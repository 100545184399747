import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_DASHBOARD_FILTER } from '../../utils/constants/constant'

const initialState = {
  dashboardData: {},
  appliedFilter: DEFAULT_DASHBOARD_FILTER,
}
const invoiceDashboardSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    clearCache: (state) => {
      state.dashboardData = {}
    },
    setDashboardData: (state, { payload }) => {
      state.dashboardData = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setDashboardData, setAppliedFilter, clearCache } =
  invoiceDashboardSlice.actions

export const invoiceDashboardSelector = (state) => state.dashboardData

export default invoiceDashboardSlice.reducer
