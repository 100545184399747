import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_COMPANY_FILTER } from '../../utils/constants/constant'
const initialState = {
  companyList: {},
  ccBccList: {},
  appliedFilter: DEFAULT_COMPANY_FILTER,
}
const companyListSlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    setCompanyList: (state, { payload }) => {
      state.companyList = payload
    },
    setCcBccList: (state, { payload }) => {
      state.ccBccList = payload
    },
     setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
    clearCache: (state) => {
      state.companyList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setCompanyList, setCcBccList, clearCache, setAppliedFilter } =
  companyListSlice.actions

export const companyListSelector = (state) => state.companyList

export default companyListSlice.reducer
