import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_PRJ_FILTER } from '../../utils/constants/constant'

const initialState = {
  projectList: {},
  appliedFilter:DEFAULT_PRJ_FILTER,
}
const projectListSlice = createSlice({
  name: 'projectList',
  initialState,
  reducers: {
    clearCache: (state) => {
      state.projectList = []
    },
    setProjectList: (state, { payload }) => {
      state.projectList = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setProjectList, setAppliedFilter,clearCache } = projectListSlice.actions

export const projectListSelector = (state) => state.projectList

export default projectListSlice.reducer
