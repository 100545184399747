import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_REV_FILTER } from '../../utils/constants/constant'
const initialState = {
  revenueList: [],
  appliedFilter: DEFAULT_REV_FILTER,
}
const revenueListSlice = createSlice({
  name: 'revenueList',
  initialState,
  reducers: {
    clearCache: (state) => {
      state.revenueList = []
    },
    setRevenueList: (state, { payload }) => {
      state.revenueList = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setRevenueList, setAppliedFilter, clearCache } =
  revenueListSlice.actions

export const revenueListSelector = (state) => state.revenueList

export default revenueListSlice.reducer
