import React, { useContext } from 'react'
import { Menu } from 'antd'
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as ProjectsIcon } from '../../assets/icons/projects.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as Company } from '../../assets/icons/company.svg'
import { ReactComponent as Activity } from '../../assets/icons/activity.svg'
import { ReactComponent as TodoList } from '../../assets/icons/todo.svg'
import { ReactComponent as Techstack } from '../../assets/icons/techstack.svg'
import { ReactComponent as SalesPersons } from '../../assets/icons/salesPersons.svg'
import { ReactComponent as RevenueIcon } from '../../assets/icons/revenue.svg'
import { ReactComponent as RateMasterIcon } from '../../assets/icons/rateMaster.svg'

import './Sidebar.scss'
import { useHistory } from 'react-router-dom'
import { Layout } from 'antd'
import useReactRouter from 'use-react-router'
import { getSideBarOptions } from '../../utils/helperFunctions/helperFunctions'
import { AuthContext } from '../../store/auth'

const { Sider } = Layout
export const sideBarOptions = [
  {
    icon: DashboardIcon,
    key: '/invoiceDashboard',
    label: 'Invoice Dashboard',
    path: '/invoiceDashboard',
    accountant: true,
    csm: true,
  },
  {
    icon: RateMasterIcon,
    key: '/rateMaster',
    label: 'Rate Master',
    path: '/rateMaster',
    accountant: true,
    csm: true,
  },
  {
    icon: ProjectsIcon,
    key: '/projectlist',
    label: 'Projects',
    path: '/projectlist',
    accountant: true,
    csm: true,
  },
  {
    icon: RevenueIcon,
    key: '/revenuelist',
    label: 'Revenue',
    path: '/revenuelist',
    accountant: true,
    csm: true,
  },
  {
    icon: Company,
    key: '/companylist',
    label: 'Company',
    path: '/companylist',
    accountant: true,
    csm: true,
  },
  {
    icon: SalesPersons,
    key: '/salesPersons',
    label: 'Sales Persons',
    path: '/salesPersons',
    accountant: false,
    csm: false,
  },
  {
    icon: TodoList,
    key: '/todolist',
    label: 'To-Do',
    path: '/todolist',
    accountant: true,
    csm: true,
  },
  {
    icon: Techstack,
    key: '/techstack',
    label: 'Techstack',
    path: '/techstack',
    accountant: false,
    csm: false,
  },
  {
    icon: Activity,
    key: '/useractivity',
    label: 'Activity',
    path: '/useractivity',
    accountant: true,
    csm: true,
  },
  {
    icon: UserIcon,
    key: '/userlist',
    label: 'User',
    path: '/userlist',
    accountant: false,
    csm: false,
  },
]

const Sidebar = () => {
  const history = useHistory()
  const { location } = useReactRouter()
  const currentSelectedItem = location.state?.sidebarSelected
  const { userRole } = useContext(AuthContext)

  function onMenuItemClick({ item, key, keyPath }) {
    // e.item here is deprecated since it will be move to function component that not provides React Node instance in future
    // So, It may need to be modified in future
    history.push({
      pathname: item.props.path,
      state: { sidebarSelected: key },
    })
  }
  return (
    <Sider width={200}>
      <Menu
        mode="inline"
        onClick={onMenuItemClick}
        defaultSelectedKeys={
          Boolean(
            location?.pathname === '/settings/jobScheduler' ||
              location?.pathname === '/settings/serverLogs' ||
              location?.pathname === '/settings/identityProvider'
          )
            ? []
            : [currentSelectedItem || '/projectlist']
        }
        defaultOpenKeys={['sub1']}
        items={getSideBarOptions(userRole, sideBarOptions)}
      />
    </Sider>
  )
}
export default Sidebar
