import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_RATE_FILTER } from '../../utils/constants/constant'

const initialState = {
  rateList: {},
  appliedFilter: DEFAULT_RATE_FILTER,
}
const rateMasterSlice = createSlice({
  name: 'rateList',
  initialState,
  reducers: {
    clearCache: (state) => {
      state.rateList = []
    },
    setRateList: (state, { payload }) => {
      state.rateList = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setRateList, setAppliedFilter, clearCache } =
  rateMasterSlice.actions

export const rateListSelector = (state) => state.rateList

export default rateMasterSlice.reducer
