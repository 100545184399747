import { API_END_POINTS } from '../constants/apiEndPointConstants'
import axios from 'axios'
import {
  getCompanyDetails,
  getCompanyDetailsSuccess,
  getCompanyDetailsFailure,
} from '../../redux/reducer/getCompanybyIdSlice'
import {
  getProjectCompanyAndClientDetails,
  getProjectCompanyAndClientDetailsSuccess,
  getProjectCompanyAndClientDetailsFailure,
} from '../../redux/reducer/projectCompanyAndClientsSlice'
import { setCurrentProjectId } from '../../redux/reducer/projectIdSlice'
import { setCurrentCompanyId } from '../../redux/reducer/companyIdSlice'
import { toastNotify } from '../helperFunctions/toastNotify'
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  TOAST,
} from '../constants/constant'

export const getCompanyAndClientsByProjectId = (projectId) => {
  const URL = `${API_END_POINTS.projects}/company/${projectId}`
  return async (dispatch) => {
    dispatch(getProjectCompanyAndClientDetails())
    dispatch(setCurrentProjectId(projectId))
    try {
      const response = await axios.get(URL)
      const clientAndCompanyData = response?.data?.data
      const dataWithProjectId = {
        ...clientAndCompanyData,
        persistedProjectId: projectId,
      }
      dispatch(getProjectCompanyAndClientDetailsSuccess(dataWithProjectId))
    } catch (error) {
      toastNotify(
        TOAST.error,
        error?.data?.message ||
          'Failed to get company and client details of project'
      )
      dispatch(getProjectCompanyAndClientDetailsFailure([]))
    }
  }
}

export const getCompanydetailsById = (companyId) => {
  const URL = `${API_END_POINTS.clients}/${companyId}`
  return async (dispatch) => {
    dispatch(getCompanyDetails())
    dispatch(setCurrentCompanyId(companyId))
    try {
      const response = await axios.get(URL)
      dispatch(getCompanyDetailsSuccess(response?.data?.data))
    } catch (error) {
      toastNotify(
        TOAST.error,
        error?.data?.message || 'Failed to get company details'
      )
      dispatch(getCompanyDetailsFailure())
    }
  }
}

export const editClient = async (client) => {
  const URL = `${API_END_POINTS.clients}`
  try {
    const response = await axios.put(URL, client)
    return response
  } catch (error) {
    return error.response
  }
}

export const addNewClients = async (companyId, clients, projectId) => {
  const URL = `${API_END_POINTS.clients}?companyId=${companyId}&projectId=${
    projectId ? projectId : 0
  }`
  try {
    const response = await axios.post(URL, clients)
    return response
  } catch (error) {
    return error?.response
  }
}

export const addEditCompany = async (companyDetails) => {
  const URL = `${API_END_POINTS.company}/v2`
  try {
    const response = await axios.post(URL, companyDetails)
    return response
  } catch (err) {
    return err.response
  }
}

export const getCompanyListService = async (
  page,
  pageSize,
  filterSource,
  signal
) => {
  const URL = `${API_END_POINTS.company}/filter/v2?pageNo=${page}&pageSize=${pageSize}`

  try {
    const response = await axios.post(URL, filterSource, { signal })
    return response
  } catch (error) {
    return error?.response
  }
}

export const getSearchCompanyList = async (
  searchText,
  signal,
  page = DEFAULT_PAGE_NO,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  const URL = `${API_END_POINTS.company}/search/v2?companyName=${searchText}&pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.get(URL, { signal })
    return response
  } catch (error) {
    return error.response
  }
}

export const getClientForSelectedCompany = async (companyId) => {
  const URL = `${API_END_POINTS.clients}?companyId=${companyId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    return []
  }
}

export const addClientsToProject = async (
  selectedCompanyId,
  projectId,
  data
) => {
  const URL = `${API_END_POINTS.projects}/company/${selectedCompanyId}/${projectId}`
  try {
    const response = await axios.post(URL, data)
    return response
  } catch (error) {
    return []
  }
}
export const deleteClientById = async (clientId) => {
  const URL = `${API_END_POINTS.clients}/${clientId}`
  try {
    const response = await axios.delete(URL)
    return response
  } catch (err) {
    console.log('error while deleting client by id', err)
  }
}

export const fetchProjectsByClientId = async (clientId, page, pageSize) => {
  const URL = `${API_END_POINTS.clients}/projects?clientId=${clientId}&pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.get(URL)
    return response.data
  } catch (error) {
    return error?.response
  }
}
export const getClientSuggestionByProjectId = async (projectId, isPrimary) => {
  const URL = `${API_END_POINTS.clients}/autoSuggestReplaceClient?projectId=${projectId}&primary=${isPrimary}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    return []
  }
}

export const updateProjectClients = async (projectDetails) => {
  const URL = `${API_END_POINTS.clients}/replace`
  try {
    const response = await axios.put(URL, projectDetails)
    return response
  } catch (err) {
    console.log('error while editing Company', err)
    return err.response
  }
} 

export const getCcBccEmailService = async () => {
  const URL = `${API_END_POINTS.ccBcc}`
  try {
    const response = await axios.get(URL)
    return response
  } catch (err) {
    console.log(err)
    return err?.response
  }
}

export const updateCcBccEmailService = async (emailList) => {
  const URL = `${API_END_POINTS.ccBcc}`
  try {
    const response = await axios.post(URL, emailList)
    return response
  } catch (error) {
    return error?.response
  }
}
