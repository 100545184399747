import axios from 'axios'
import {
  getPredictionDetails,
  getPredictionDetailsFailure,
  getPredictionDetailsSuccess,
} from '../../redux/reducer/getPredictionsByIdSlice'
import { setCurrentProjectId } from '../../redux/reducer/projectIdSlice'
import { API_END_POINTS } from '../constants/apiEndPointConstants'
import { toastNotify } from '../helperFunctions/toastNotify'
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_REV_FILTER,
  TOAST,
} from '../constants/constant'

export const fetchPredictionsProjects = async (
  page,
  pageSize,
  status,
  projectName = '',
  signal
) => {
  const URL = `${API_END_POINTS.predictionProjects}?projectName=${projectName}&status=${status}&pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.get(URL, { signal })
    return response?.data?.data
  } catch (error) {
    console.log(error?.message)
  }
}

export const getPredictionDetailsById = (projectId) => {
  const URL = `${API_END_POINTS.predictionProjects}/andPendingInvoices/${projectId}`
  return async (dispatch) => {
    dispatch(getPredictionDetails())
    dispatch(setCurrentProjectId(projectId))
    try {
      const response = await axios.get(URL)
      const predictionDetails = response?.data?.data
      dispatch(getPredictionDetailsSuccess(predictionDetails))
    } catch (error) {
      toastNotify(
        TOAST.error,
        error?.data?.message || 'Failed to get prediction details! '
      )
      dispatch(getPredictionDetailsFailure())
    }
  }
}

export const addMultiplePredictions = async (data) => {
  const URL = API_END_POINTS.predictionProjects
  try {
    const response = await axios.post(URL, data)
    return response
  } catch (err) {
    return err?.response
  }
}

export const getViewInvoiceDetailsByInvoiceId = async (invoiceId) => {
  const URL = `${API_END_POINTS.projectsInvoices}/${invoiceId}`
  try {
    const response = await axios.get(URL)
    return response?.data
  } catch (err) {
    console.log('error while getting view invoice details', err)
  }
}

export const saveInvoice = async (
  zohoEmployeeMetadataList,
  invoiceGenerateDetails,
  invoiceLineItemList
) => {
  const URL = `${API_END_POINTS.projectsInvoices}`
  try {
    const response = await axios.post(URL, {
      zohoEmployeeMetadataList,
      invoiceGenerateDetails,
      invoiceLineItemList,
    })
    return response
  } catch (err) {
    return err?.response
  }
}

export const updateInvoice = async (
  zohoEmployeeMetadataList,
  invoiceGenerateDetails,
  invoiceLineItemList
) => {
  const URL = `${API_END_POINTS.projectsInvoices}`
  try {
    const response = await axios.put(URL, {
      zohoEmployeeMetadataList,
      invoiceGenerateDetails,
      invoiceLineItemList,
    })
    return response
  } catch (err) {
    return err?.response
  }
}

export const updatePaymentDetails = async (data) => {
  const URL = `${API_END_POINTS.projectsInvoices}`
  try {
    const response = await axios.patch(URL, data)
    return response
  } catch (err) {
    console.log('error while updating Payment Details', err)
  }
}

export const updatePredictionDetails = async (data) => {
  const URL = API_END_POINTS.predictionProjects
  try {
    const response = await axios.put(URL, data)
    return response
  } catch (err) {
    return err?.response
  }
}

export const deletePredictionById = async (predictionId) => {
  const URL = `${API_END_POINTS.predictionProjects}/${predictionId}`
  try {
    const response = await axios.delete(URL)
    return response
  } catch (err) {
    console.log('error while deleting prediction by id', err)
  }
}

export const getExistingPredictionForGivenDates = async (
  projectId,
  startDate,
  endDate
) => {
  const URL = `${API_END_POINTS.predictionProjects}/between/${projectId}?startDate=${startDate}&endDate=${endDate}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (err) {
    console.log(
      'error while getting existing prediction between given dates',
      err
    )
  }
}

export const fetchReceivedInvoices = async (invoiceNumber) => {
  const URL = `${API_END_POINTS.projectsInvoices}/filter`
  const data = {
    ...DEFAULT_REV_FILTER,
    receivedMonth: [],
    invoiceNumber: [invoiceNumber],
  }
  try {
    const response = await axios.post(URL, data)
    return response
  } catch (err) {
    console.log('error while getting Received Invoices', err)
    return err.response
  }
}

export const editReceivedInvoices = async (updatedData) => {
  const URL = `${API_END_POINTS.projectsInvoices}`
  try {
    const response = await axios.put(URL, updatedData)
    return response?.data
  } catch (error) {
    console.log('error in edit received invoices', error)
  }
}

export const getFilteredInvoices = async (filterValues, page, pageSize) => {
  let queryParameter = ''
  filterValues.forEach((value) => {
    let key = Object.keys(value)[0]
    value = value[key]
    queryParameter += `${key}=${value}&`
  })
  const URL = `${API_END_POINTS.projectsInvoices}/filter?${queryParameter}pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.get(URL)
    return response
  } catch (error) {
    return error?.response
  }
}

export const getInvoiceDetails = async (query, page, pageSize, signal) => {
  const URL = `${API_END_POINTS.projectsInvoices}/all?query=${query}&pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.get(URL, { signal })
    return response
  } catch (error) {
    console.log(error?.message)
  }
}
export const getPredictionById = async (predictionId) => {
  const URL = `${API_END_POINTS.predictionProjects}/${predictionId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    console.log(error?.message)
  }
}

export const getInvoiceById = async (invoiceId) => {
  const URL = `${API_END_POINTS.projectsInvoices}/${invoiceId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    console.log(error?.message)
  }
}
export const getLatestPredictionOfProject = async (projectId) => {
  const URL = `${API_END_POINTS.predictionProjects}/latest/${projectId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    console.log(error?.message)
  }
}
export const downloadTimesheet = async (timeSheetDataFormat) => {
  const URL = `${API_END_POINTS.projectsInvoices}/download`
  try {
    const response = await axios.post(URL, timeSheetDataFormat)
    return response
  } catch (error) {
    console.log('csv error', error)
    error?.message && toastNotify(TOAST.error, error?.message)
  }
}
export const fetchTimesheetEmployee = async (
  zohoProjectId,
  endDate,
  timesheetData,
  timesheetStartDate,
  timesheetEndDate
) => {
  const URL = `${API_END_POINTS.timesheet}/dd/${zohoProjectId}?endDate=${endDate}&timesheetStartDate=${timesheetStartDate}&timesheetEndDate=${timesheetEndDate}`
  try {
    const response = await axios.post(URL, timesheetData)
    return response?.data?.data
  } catch (error) {
    console.log('fetch timesheet employee error', error)
  }
}

export const getEmployeeDifference = async (
  projectId,
  zohoEmployeeMetadataList
) => {
  const URL = `${API_END_POINTS.projectsInvoices}/compare?projectId=${projectId}`
  try {
    const response = await axios.post(URL, zohoEmployeeMetadataList)
    return response?.data
  } catch (error) {
    console.log('get employee difference error', error)
  }
}
export const fetchSearchPredictions = async (
  projectName,
  signal,
  page = DEFAULT_PAGE_NO,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  const URL = `${
    API_END_POINTS.projects
  }/search?projectName=${encodeURIComponent(
    projectName
  )}&pageNo=${page}&pageSize=${pageSize}`

  try {
    const response = await axios.get(URL, { signal })
    return response?.data?.data
  } catch (err) {
    console.log(err?.message)
  }
}

export const mapInvoiceServices = async (invoiceId, booksInvoiceNumber) => {
  const URL = `${API_END_POINTS.projectsInvoices}/map?invoiceId=${invoiceId}&booksInvoiceNumber=${booksInvoiceNumber}`
  try {
    const response = await axios.post(URL)
    return response
  } catch (err) {
    return err?.response
  }
}

export const deleteInvoiceService = async (invoiceId) => {
  const URL = `${API_END_POINTS.projectsInvoices}/${invoiceId}`
  try {
    const response = await axios.delete(URL)
    return response
  } catch (error) {
    return error?.response
  }
}

export const exportTimesheetService = async (
  zohoProjectId,
  startDate,
  endDate,
  predictionId,
  timesheetStartDate,
  timesheetEndDate
) => {
  const URL = `${API_END_POINTS.sheets}/${zohoProjectId}?startDate=${startDate}&endDate=${endDate}&predictionId=${predictionId}&timesheetStartDate=${timesheetStartDate}&timesheetEndDate=${timesheetEndDate}`
  try {
    const response = await axios.post(URL)
    return response
  } catch (error) {
    return error?.response
  }
}

export const updateRevenueDetails = async (data) => {
  const URL = `${API_END_POINTS.revenue}`
  try {
    const response = await axios.put(URL, data)
    return response
  } catch (error) {
    return error?.response
  }
}
export const getFilteredRevenueListService = async (
  page,
  pageSize,
  filterSource,
  signal
) => {
  const URL = `${API_END_POINTS.revenue}/filter?pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.post(URL, filterSource, { signal })
    return response
  } catch (error) {
    return error?.response
  }
}

export const fetchSearchRevenueService = async (
  projectName,
  signal,
  page = DEFAULT_PAGE_NO,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  const URL = `${
    API_END_POINTS.revenue
  }/search?projectName=${encodeURIComponent(
    projectName
  )}&pageNo=${page}&pageSize=${pageSize}`

  try {
    const response = await axios.get(URL, { signal })
    return response
  } catch (err) {
    return err?.response
  }
}


export const rcvMonthSyncService = async () => {
  const URL = `${API_END_POINTS.revenue}/currentMonth`
  try {
    const response = await axios.post(URL)
    return response
  }
  catch (error) {
    return error?.response
  }
}

export const refreshTimesheetDataService = async (invoiceId) => {
  const URL = `${API_END_POINTS.projectsInvoices}/timesheet/${invoiceId}`
  try {
    const response = await axios.get(URL)
    return response
  }
  catch (error) {
    return error?.response
  }
}