import { getSubstringBeforeUnderscore } from '../helperFunctions/helperFunctions'
import {
  emailValidation,
  passwordRegEx,
  phoneNumberValidation,
  websiteRegex,
  whitespaceRegEx,
} from '../validations'
import {
  nonTaxRegion,
  providerOptions,
  validDomainNames,
  validRegionPrefixes,
} from './constant'
import { PLACEHOLDER_MESSAGES, VALIDATION_MESSAGES } from './messages'

export const clientNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.clientNameMessage,
  },
]

export const emailRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.emailAddressMessage,
  },
  {
    validator: emailValidation,
  },
]
export const ccBccEmailRule = (require) => [
  { validator: emailValidation },
  {
    required: require,
    message: VALIDATION_MESSAGES.emailAddressMessage,
  },
]

export const phoneNumberRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.phoneNumberMessage,
  },
  { validator: phoneNumberValidation },
]
export const clientPhoneNumberRule = [{ validator: phoneNumberValidation }]

export const displayNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.displayNameMessage,
  },
]

export const companyNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.companyNameMessage,
  },
]

export const nonRequiredPhoneNumberRule = [
  {
    validator: phoneNumberValidation,
  },
]

export const accountStartDateRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.accountStartDateMessage,
  },
]

export const websiteRule = [
  {
    pattern: websiteRegex,
    message: VALIDATION_MESSAGES.websitePatternMessage,
  },
]

export const countryRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.countryMessage,
  },
  {
    min: 2,
    message: VALIDATION_MESSAGES.countryNameMinLengthMessage,
  },
  {
    max: 50,
  },
]

export const stateRule = [
  {
    min: 2,
    message: VALIDATION_MESSAGES.stateNameMinLengthMessage,
  },
  {
    max: 30,
    message: VALIDATION_MESSAGES.stateNameMaxLengthMessage,
  },
]

export const cityRule = [
  {
    min: 2,
    message: VALIDATION_MESSAGES.cityNameMinLengthMessage,
  },
  {
    max: 30,
    message: VALIDATION_MESSAGES.cityNameMaxLengthMessage,
  },
]

export const rateRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.rateMessage,
  },
]

export const accountantRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.accountantMessage,
  },
]

export const leadSourceRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.leadSourceMessage,
  },
]

export const activeBillingRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.activeBillingMessage,
  },
]
export const invoiceTemplateRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.invoiceTemplateMessage,
  },
]

export const salesManRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.salesmanMessage,
  },
]

export const secondaryCSMRule = [
  {
    required: true,
    message: 'Please select secondary CSM',
  },
]

export const accuracyRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.accuracyMessage,
  },
]

export const amountRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.amountMessage,
  },
  {
    pattern: new RegExp(/^-?\d*\.\d{0,2}$|^-?\d+$/),
    message: VALIDATION_MESSAGES.amountPatternMessage,
  },
]

export const startDateRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.startDateMessage,
  },
]

export const endDateRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.endDateMessage,
  },
]

export const receivedMonthRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.receivedMonthMessage,
  },
]

export const payModelRule = [
  {
    required: true,
    message: 'Please select pay model',
  },
]

export const receivedAmountRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.receivedAmountMessage,
  },
]

export const receivedAmountValueRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.receivedAmountMessage,
  },
  {
    pattern: new RegExp(/^-?\d*\.\d{0,2}$|^-?\d+$/),
    message: VALIDATION_MESSAGES.amountPatternMessage,
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      const outStandingAmount = getFieldValue('outStandingAmount')
      if (Number(value) > Number(outStandingAmount)) {
        return Promise.reject(
          value &&
            new Error(
              'Received amount can not be greater than outstanding amount'
            )
        )
      }
      return Promise.resolve()
    },
  }),
]

export const invoiceStatusRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.invoiceStatusMessage,
  },
]

export const invoiceDateRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.invoiceDateMessage,
  },
]
export const invoiceDueDateRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.invoiceDueDateMessage,
  },
]

export const zohoProjectIdRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.zohoProjectIdMessage,
  },
  {
    pattern: whitespaceRegEx,
    message: VALIDATION_MESSAGES.zohoProjectIdPatternMessage,
  },
]

export const projectNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.projectNameMessage,
  },
  {
    min: 3,
    message: VALIDATION_MESSAGES.projectNamePatternMessage,
  },
]

export const projectModelRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.projectModelMessage,
  },
]

export const ratePatternRule = [
  {
    pattern: new RegExp(/^\d+\.?\d{0,4}$/),
    message: VALIDATION_MESSAGES.ratePatternMessage,
  },
]

export const zohoUserNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.zohoUserNameRule,
  },
]

export const priceRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.priceMessage,
  },
  {
    pattern: new RegExp(/^\d+\.?\d{0,4}$/),
    message: VALIDATION_MESSAGES.pricePattternMessage,
  },
]

export const zohoUserIdRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.zohoUserIdMessage,
  },
  {
    pattern: new RegExp(/^\d{9}$/),
    message: VALIDATION_MESSAGES.zohoUserIdPatternMessage,
  },
]

export const billedHoursRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.billedHoursMessage,
  },
]

export const overTimeHoursRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.overTimeHoursMessage,
  },
]

export const userNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.userNameMessage,
  },
]
export const employeeNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.employeeNameMessage,
  },
]

export const userRoleRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.userRoleMessage,
  },
]

export const categoryRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.categoryMessage,
  },
]

  export const userEmailRule = [
    {
      required: true,
      message: VALIDATION_MESSAGES.emailAddressMessage,
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.resolve()
        }

        // Check for spaces within the email
        if (value.includes(' ')) {
          return Promise.reject(
            new Error(VALIDATION_MESSAGES.noSpacesAllowedMessage)
          )
        }

        // Basic email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(value)) {
          return Promise.reject(
            new Error(VALIDATION_MESSAGES.invalidEmailFormatMessage)
          )
        }

        // Domain validation
        const idx = value.lastIndexOf('@')
        const domainName = value.slice(idx + 1)
        if (validDomainNames.includes(domainName)) {
          return Promise.resolve()
        }

        return Promise.reject(
          new Error(VALIDATION_MESSAGES.validDomainNameMessage)
        )
      },
    },
  ]

export const passwordRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.passwordMessage,
  },
]

export const newPasswordRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.newPasswordMessage,
  },
  {
    pattern: passwordRegEx,
    message: VALIDATION_MESSAGES.validPasswordPatternMessage,
  },
  {
    pattern: whitespaceRegEx,
    message: VALIDATION_MESSAGES.noWhiteSpaceInPasswordMessage,
  },
]

export const confirmPasswordRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.confirmPasswordMessage,
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error(VALIDATION_MESSAGES.passwordsShouldBeSameMessage)
      )
    },
  }),
]
export const leaveTermRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.leaveTermMessage,
  },
  {
    pattern: new RegExp(/^\d*\.?\d*$/),
    message: VALIDATION_MESSAGES.leaveTermPatternMessage,
  },
]

export const invoiceCycleRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.invoiceCycleMessage,
  },
]

// Here the globalTaxField is required if the qbRegion is other than 'US'
export const globalTaxSettingRules = (region) => {
  const regionSubString = getSubstringBeforeUnderscore(region)
  return [
    {
      required: !nonTaxRegion.includes(regionSubString),
      message: VALIDATION_MESSAGES.globalTaxSettingMessage,
    },
  ]
}

// Here the taxRate is required if the globalTaxSetting is selected and is other than 'Not Applicable'

export const taxRateRules = (selectedGlobalTaxSetting) => [
  {
    required:
      selectedGlobalTaxSetting && selectedGlobalTaxSetting !== 'Not Applicable',
    message: VALIDATION_MESSAGES.taxRateMessage,
  },
]

export const retainerRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.retainerMessage,
  },
  {
    pattern: new RegExp(/^\d+$/),
    message: VALIDATION_MESSAGES.retainerPatternMessage,
  },
]

export const incentiveValueRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.incentiveValueMessage,
  },
]

export const accountNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.accountNameMessage,
  },
]

export const dateRangeValidationRule = (isDisabledDateRange) => [
  {
    required: !isDisabledDateRange,
    message: VALIDATION_MESSAGES.dateRangeMessage,
  },
]

export const messageOnInvoiceStatementRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.messageOnInvoiceStatementMessage,
  },
]
export const invoiceNumberRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.invoiceNumberMessage,
  },
]

export const activeBalanceDateRule = (activeBalance) => [
  {
    required: Boolean(activeBalance),
    message: VALIDATION_MESSAGES.activeBalanceDateMessage,
  },
]
export const holidayDateRule = [
  {
    required: true,
    message: PLACEHOLDER_MESSAGES.holidayDateMessage,
  },
]
export const holidayNameRule = [
  {
    required: true,
    message: PLACEHOLDER_MESSAGES.holidayNameMessage,
  },
]
export const activeBalanceRule = [
  {
    pattern: new RegExp(/^-?\d+(?:\.\d{1,4})?$/),
    message: VALIDATION_MESSAGES.activeBalanceMessage,
  },
]
export const receivedDateRule = (require) => {
  return [
    {
      required: require,
      message: VALIDATION_MESSAGES.receivedDateMessage,
    },
  ]
}
export const providerNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.providerNameMessage,
  },
]

export const regionRule = (provider) => {
  return providerOptions.includes(provider)
    ? [
        {
          required: true,
          message: VALIDATION_MESSAGES.regionsMessage,
        },
        {
          pattern: new RegExp(`^(${validRegionPrefixes.join('|')})(_.*)?$`),
          message: VALIDATION_MESSAGES.validRegionMessage,
        },
      ]
    : [
        {
          required: true,
          message: VALIDATION_MESSAGES.regionsMessage,
        },
      ]
}

export const clientIdRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.clientIdMessage,
  },
]

export const clientSecretRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.clientSecretMessage,
  },
]

export const taxTreatmentRules = [
  {
    required: true,
    message: VALIDATION_MESSAGES.taxTreatmentMessage,
  },
]

export const trnNoRules = [
  {
    required: true,
    message: VALIDATION_MESSAGES.trnNoMessage,
  },
]

export const placeOfContactRules = [
  {
    required: true,
    message: VALIDATION_MESSAGES.placeOfContactMessage,
  },
]

export const paymentTermsRules = [
  {
    required: true,
    message: VALIDATION_MESSAGES.paymentTermsMessage,
  },
]

export const organizationNameRule = [
  {
    required: true,
    message: VALIDATION_MESSAGES.organizationNameMessage,
  },
]

export const redirectUrlRules = (isRequired) => {
  return [
    {
      required: isRequired,
      message: VALIDATION_MESSAGES.redirectURLMessage,
    },
  ]
}
export const scopeCodeRule = (isRequired) => {
  return [
    {
      required: isRequired,
      message: VALIDATION_MESSAGES.scopeCodeMessage,
    },
  ]
}
export const realmIdRules = (isRequired) => {
  return [
    {
      required: isRequired,
      messsage: VALIDATION_MESSAGES.realmIdMessage,
    },
  ]
}
export const linkTitleRules = [
  {
    required: true,
    message: VALIDATION_MESSAGES.linkTitleMessage,
  },
]
export const linkUrlRules = [
  {
    required: true,
    message: VALIDATION_MESSAGES.linkUrlMessage,
  },
]
export const subMissionTypeRule = [{
  required: true,
  message: VALIDATION_MESSAGES.subMissionTypeMessage
}]

export const generateDataRules = [{
  required: true,
  message:VALIDATION_MESSAGES.selectMonthMessage
}]